<template>
  <div class="home">
    <h4>添加渠道<router-link :to="{path:'/channel'}" class="float-right btn btn-outline-secondary btn-sm">返回列表</router-link></h4>
    <div class="my-3">
      <form action="" @submit="onSubmit">


        <div class="mb-3 row">
          <label for="inputEmail" class="col-sm-2 col-form-label">联系邮箱</label>
          <div class="col-sm-10">
            <input type="email" name="mail" class="form-control" id="inputEmail" v-model="form.mail">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputPhone" class="col-sm-2 col-form-label">手机号码</label>
          <div class="col-sm-10">
            <input type="text" name="phone" class="form-control" id="inputPhone" v-model="form.phone">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputEmail" class="col-sm-2 col-form-label">公司名称</label>
          <div class="col-sm-10">
            <input type="text" name="company" class="form-control" v-model="form.company" autocomplete="new-password">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputEmail" class="col-sm-2 col-form-label">密码</label>
          <div class="col-sm-10">
            <input type="password" name="password" class="form-control" v-model="form.password" autocomplete="new-password">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputEmail" class="col-sm-2 col-form-label">重复密码</label>
          <div class="col-sm-10">
            <input type="repassword" name="repassword" class="form-control" v-model="form.repassword" autocomplete="new-password">
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputContact" class="col-sm-2 col-form-label">联系人</label>
          <div class="col-sm-10">
            <input type="text" name="contact" class="form-control" id="inputContact" v-model="form.contact">
          </div>
        </div>

        <div class="mb-3 row">
          <label for="inputRatio" class="col-sm-2 col-form-label">积分比例</label>
          <div class="col-sm-10">
            <input type="text" name="ratio" class="form-control" id="inputRatio" v-model="form.ratio">
          </div>
        </div>

        <div class="mb-3 row">
          <label for="inputBody" class="col-sm-2 col-form-label">备注</label>
          <div class="col-sm-10">
            <textarea class="form-control" name="body" id="inputBody" v-model="form.body"></textarea>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">保存</button>
      </form>
    </div>

  </div>
</template>

<script>
import Channel from "@/model/Channel"


export default {
  name: 'Add',
  data() {
    return {
      form: {
        mail: '',
        phone: '',
        contact: '',
        company: '',
        inputBody: '',
        ratio: ''
      },
      isBtnDisabled: false
    }
  },
  mounted() {
    console.log('add')
  },
  methods: {
    init() {
    },
    validate() {
      let error = [];
      error[0] = 'hello';
      return error;
    },
    onSubmit(e){
      e.preventDefault();
      this.isBtnDisabled = true;
      let error = this.validate()
      if(error.length > 0) {
        for(let i in error) {
          this.$bvToast.toast(error[i])
        }
        this.isBtnDisabled = false;
      } else {
        Channel.save(this.form).then(response => {
          if(response.data.code === 0) {
            this.$bvToast.toast('保存成功');
            setTimeout(() => {
              this.$router.replace({path: '/channel'});
            })
          } else {
            this.$bvToast.toast('保存失败');
            this.isBtnDisabled = false;
          }
        })
      }


    }
  }
}
</script>